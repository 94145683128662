<template>
  <div>
    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="employee_id">{{ $t('sales_commission_details.employee') }}</label>
              <!-- <select name="" id="employee_id" v-model="filters.employee_id" class="custom-select" >
                  <option v-for="row in employees" :value="row.id" :key="row.id">
                      {{ row.full_name }}
                  </option>
              </select> -->
              <multiselect v-model="employee"
                           :placeholder="$t('sales_commission_details.employee')"
                           label="full_name"
                           track-by="id"
                           :options="employees"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @search-change="getEmployees($event)">
              </multiselect>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('sales_commission_details.sales_commission') }}</label>
              <!-- <select name="" id="commission_period" v-model="filters.sales_commission_id" class="custom-select" >
                  <option v-for="row in commision_sales_list" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select> -->
              <multiselect v-model="sales_commission"
                           :placeholder="$t('sales_commission_details.sales_commission')"
                           label="name"
                           track-by="id"
                           :options="commision_sales_list"
                           :multiple="false"
                           :taggable="true"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('sales_commission_details.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('sales_commission_details.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>


            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="transaction_type">{{ $t('sales_commission_details.transaction_type') }}</label>
              <select name="" id="transaction_type" v-model="filters.transaction_type" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in transaction_type_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="actions" slot-scope="props">

            <v-btn icon color="pink" :to="`/salestargetcommission/sales-commission-details/view/${props.row.id}`">
              <v-icon small v-b-tooltip.hover :title="$t('view')" class="mr-2">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-sales-commission-details",

  data() {
    return {
      mainRoute: 'salestargetcommission/sales-commission-details',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        employee_id: null,
        from_date: null,
        to_date: null,
        sales_commission_id: null,
        transaction_type: null,
      },
      transaction_type_list: [
        {id: 1, text: this.$t('sales_commission_details.sales_invoices')},
        {id: 2, text: this.$t('sales_commission_details.sales_credit')},
        {id: 3, text: this.$t('sales_commission_details.sales_refund')},
      ],
      columns: ['id', 'employee_name', 'transaction', 'transaction_date', 'sales_amount', 'sales_quantity', 'total_commission', 'actions'],
      data: [],
      commision_sales_list: [],

      employees: [],
      employee: null,
      sales_commission: null,

    }
  },
  watch: {
    employee: function (val) {
      if (val) {
        this.filters.employee_id = val.id;
      } else {
        this.filters.employee_id = null;
      }
    },
    sales_commission: function (val) {
      if (val) {
        this.filters.commision_sales_id = val.id;
      } else {
        this.filters.commision_sales_id = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('sales_commission_details.id'),
          employee_name: that.$t('sales_commission_details.employee'),
          transaction: that.$t('sales_commission_details.transaction'),
          transaction_date: that.$t('sales_commission_details.transaction_date'),
          sales_amount: that.$t('sales_commission_details.sales_amount'),
          sales_quantity: that.$t('sales_commission_details.sales_quantity'),
          total_commission: that.$t('sales_commission_details.total_commission'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_commission_details")}]);
    this.getCommissionSales();
    // this.getEmployees();
  },
  methods: {


    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.employee_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.sales_commission_id = null;
      this.filters.transaction_type = null;
      this.employee = null;
      this.sales_commission = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getEmployees(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params: {name: filter}}).then((response) => {
          this.employees = response.data.data;
        });
    },
    getCommissionSales() {
      ApiService.get(this.mainRouteDependency + "/commision_sales").then((response) => {
        this.commision_sales_list = response.data.data;
      });
    },
    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        status: (status ? '1' : '0'),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },


  },
};
</script>
